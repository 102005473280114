import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v2';
import Intro from './home-components/call-to-action-home';
import BlockHome from './home-components/block-home';
import BlockHomeTwo from './home-components/block-home-two';
import ServicesHome from './home-components/services-home';
import Directories from './home-components/directorios';
import Colors from './home-components/ColorPalette';
import Footer from './global-components/footer';
import ModalComponent from './home-components/modal-component';
import AboutPage from './section-components/about-v2';
import Values from './section-components/values';
import Map from './contact-component/map';
import VideoPromocional from './home-components/VideoPromocional';

const Home_V2 = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <Banner />
        <Intro />
        <BlockHome />
        <Directories />
        <BlockHomeTwo />
        <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={rpdata?.stock?.[1]}
        />
        <AboutPage bgimg="17" />
        <Values />
        <Colors />
        <ServicesHome />
        <Map />
        <ModalComponent />
        <Footer />
    </div>
}

export default Home_V2

